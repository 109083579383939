import React from 'react'
import { UsePopup } from './PopUpContext'
import "../../access/style/popUp.scss";

const PopUpTrigger = ({children, content}) => {
  const { triggerPopup } = UsePopup()
  
  return (
    // <button onClick={() => triggerPopup('Popup Triggered')}>
    <button className='popUpTrigger' onClick={() => triggerPopup(
      content
    )}>
      {children}
    </button>
  )
}

export default PopUpTrigger;