export const getMomoImages = async () => {
    //const allImages = await fetch("../../public/momo-image");
    let images = [];

    const allImages = await require.context('../../public/momo-image', false, /\.(png|jpe?g|svg|JPG|PNG)$/);
    allImages.keys().map((item, index) => {
        images.push(item.replace('./', ''));
    });

    return images;
}