import React, { useEffect } from 'react'
import { UsePopup } from './PopUpContext'
import "../../access/style/popUp.scss";

const Popup = () => {
   const { value, clearPopup } = UsePopup()
   
  //  useEffect(() => {
  //     const timer = setTimeout(() => {
  //       clearPopup()
  //     }, 30000)
  //     return () => clearTimeout(timer)
  //  }, [value])
  
  return value ? 
  <div className='popupContainer'>
    <div className='popup'>
      <button className='closeBtn' onClick={clearPopup}>x</button>
      {value}
    </div>
  </div> 
  : null
}
  
export default Popup