
import React from 'react'
import { PopupProvider } from '../components/PopUps/PopUpContext'
import Popup from '../components/PopUps/PopUp'
import PopUpTrigger from '../components/PopUps/PopUpTrigger'

const Test = () => {
    return (
        <PopupProvider>
            <Popup />
            <PopUpTrigger/>                
        </PopupProvider>
    );
};

export default Test;