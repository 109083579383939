import {Link} from "react-router-dom";
import "../access/style/nav.scss";

const NavBar = () => {
    return <nav className="Nav">
        <img src="/favicon.png" className="momoIcon"/>
        <ul>
            <li>
                <Link to="/">Home</Link>
            </li>
        </ul>
    </nav>
} 

export default NavBar;