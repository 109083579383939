import "../access/style/home.scss";
import { getMomoImages } from "../service/home";
import React, { useState, useEffect } from 'react';
import { PopupProvider } from '../components/PopUps/PopUpContext';
import Popup from '../components/PopUps/PopUp';
import PopUpTrigger from '../components/PopUps/PopUpTrigger'

const Home = () => {

    const [momoImages, setMomoImages] = useState([]);

    const updateImgaes = async() => {
        if(momoImages.length <= 0) {
            const images = await getMomoImages();
            setMomoImages(images);
            console.log(momoImages)
        }
    }

    useEffect(() => {
        updateImgaes();
    }, []);

    const showImages = () => {
        // const { triggerPopup } = UsePopup();
        // console.log(triggerPopup)
        // triggerPopup(
        //     <div>test</div>
        // );
    }

    return (
        <div className="Home">
            <div className="banner">
                <div className="bannerContent">
                    <div className="title">Momo's Gallery</div>
                    <div className="content">Momo images here!!</div>
                </div>
            </div>
            <div className="content">
                {
                momoImages.map(item => 
                    <div className="momoImagesContainer" onClick={showImages} key={item}>
                        <PopupProvider>
                            <Popup />
                            <PopUpTrigger
                            content={
                                <div className="popUpWindow">
                                    <img src={"/momo-image/" + item} alt={item} className="popUpImg"/>
                                </div>
                            }
                            children={<img src={"/momo-image/" + item} alt={item} className="momoImages"/>}
                            />                
                        </PopupProvider>
                    </div>)
                }
            </div>
        </div>
    );
};

export default Home;