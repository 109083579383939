import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Layout from "./pages/Layout";
import NavBar from "./parts/nav";
import Test from './pages/TestPage';

function App() {
  return (
    <Router>
      <div>
        <NavBar/>        
        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/test" element={<Test />} />
          {/* <Route path="/players">
            {players.map((player) => (
              <Route
                key={player.name}
                path={`/players/${player.name}`}
                element={<Player player={player} />}
              />
            ))}
            </Route> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
